//libraries
import * as React from "react";
//import components
import Profile from "../profile";

export interface PlayerCardProps {
  year: string;
  players: Array<any>;
  images: Array<any>;
}

const PlayerCard: React.FC<PlayerCardProps> = ({ year, players, images }) => (
  <div className="text-center bg-white rounded-lg shadow relative">
    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green text-gray-800 absolute top-1 right-1">
      {year}
    </span>
    <div className="flex">
      {players.map((player) => (
        <Profile
          key={player.name}
          name={player.name}
          photo={player.photo}
          title={
            player.gender === "male" ? "Best Male Player" : "Best Female Player"
          }
          images={images}
        />
      ))}
    </div>
  </div>
);

export default PlayerCard;
