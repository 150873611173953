//libraries
import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
//containers
import Layout from "../containers/layout";
//components
import Seo from "../components/seo";
import Container from "../containers/container";
import PlayerCard from "../components/players/player-card";
//hooks
import useImages from "../hooks/useImages";

interface PlayerProps {
  name: string;
  photo: string;
  gender: string;
  year: string;
}

const Players: React.FC<PlayerProps> = () => {
  const images = useImages();
  const { people } = useStaticQuery(
    graphql`
      query {
        people: allPeopleCsv(filter: { bestYears: { ne: "" } }) {
          nodes {
            name
            photo
            gender
            bestYears
          }
        }
      }
    `
  );

  const years: Array<string> = people.nodes
    .map((person) => person.bestYears)
    .join(";")
    .split(";")
    .map((s) => s.trim());
  const uniqueYears = [...new Set(years)].sort((a, b) => +a - +b);
  const playerCards = uniqueYears.map((year: string) => {
    const playersOfTheYear = people.nodes.filter((person) =>
      person.bestYears.includes(year)
    );
    return {
      year,
      players: playersOfTheYear,
    };
  });

  return (
    <Layout className="bg-light">
      <Seo
        title="Best Players"
        description="explore best seychelles tennis players of the year"
      />
      <Container className="py-12 sm:py-24">
        <h3 className="text-center font-semibold text-3xl lg:text-4xl text-blue-500 mb-8 sm:mb-12">
          BEST TENNIS PLAYERS OF THE YEAR
        </h3>
        <div className="w-6 h-1 mb-4 bg-green mx-auto" />
        <p className="lg:max-w-2xl mx-auto uppercase text-center text-xs font-light leading-6 sm:text-sm md:text-base text-blue-400 mb-10 sm:mb-16 lg:mb-24">
          PLAYERS IN SEYCHELLES TENNIS ASSOCIATION
        </p>
        <div className="grid grid-cols-1 gap-4 sm:gap-6 md:grid-cols-2">
          {playerCards.map((card) => (
            <PlayerCard
              key={card.year}
              year={card.year}
              players={card.players}
              images={images}
            />
          ))}
        </div>
      </Container>
    </Layout>
  );
};

export default Players;
